<template id="media-asset">
  <form
    ref="form"
    @submit.stop.prevent="idMediaAsset ? updateMutate() : createMutate()"
  >
    <b-row class="mb-3 align-items-center">
      <b-col>
        <h2>Media Asset</h2>
        <h5 class="text-primary">
          {{ $t('confMediaAsset') }}
        </h5>
      </b-col>
      <b-col style="text-align: end;">
        <b-button
          variant="success"
          type="submit"
        >
          {{ $t('dataGeneric.save') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          :label="$t('UrlMedia') + ' *'"
          :invalid-feedback="$t('required')"
        >
          <b-form-input
            id="url-input"
            v-model="urlMediaAsset"
            :placeholder="$t('UrlMedia')"
            maxlength="1000"
            required
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group :label="$t('tipoAM')">
          <b-form-select
            v-model="typeMediaAsset"
            :options="mediaAssetTypes"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group :label="$t('tipoDrm')">
          <b-form-select
            v-model="typesDRM"
            :disabled="deactiveDrmType()"
            @change="drmLicenseServUrl = ''
                     drmFairplayCertUrl = ''"
          >
            <b-form-select-option
              v-for="typ in Object.keys(drmTypes)"
              :key="typ"
              :disabled="disabledOptions(typ)"
              :value="typ"
            >
              {{ drmTypes[typ] }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group :label="$t('tipoCodec')">
          <b-form-select
            v-model="typeCOD"
            :options="codecTypes"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group :label="$t('qualityMed')">
          <b-form-select
            v-model="quality"
            :options="qualityOptions"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div v-if="typesDRM != 'null' && typesDRM != null">
      <b-row>
        <b-col>
          <b-form-group
            :label="$t('drmLicenseServUrl') + ' *'"
            :invalid-feedback="$t('required')"
          >
            <b-form-input
              id="url-input"
              v-model="drmLicenseServUrl"
              :placeholder="$t('drmLicenseServUrl')"
              maxlength="1000"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="typesDRM == 'FAI'">
        <b-col>
          <b-form-group
            :label="$t('drmFairplayCertUrl') + ' *'"
            :invalid-feedback="$t('required')"
          >
            <b-form-input
              id="url-input"
              v-model="drmFairplayCertUrl"
              :placeholder="$t('drmFairplayCertUrl')"
              maxlength="1000"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col>
        <b-form-group :label="$t('dataGeneric.activeQ')">
          <b-form-checkbox
            v-model="isActive"
            switch
          />
        </b-form-group>
      </b-col>
    </b-row>
  </form>
</template>
<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BFormSelectOption,
  BButton,
  BFormGroup,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import {
  messageError, showToast, utf8ToB64, camelToKebab,
} from '@/store/functions'
import { getUserData } from '@/auth/utils'
import { getQualityOptions } from '@/@core/utils/utils'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BButton,
  },

  directives: {
    Ripple,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    mediaAsset: { default: null, type: Object },
    updateAction: { default: false, type: Boolean },
    base: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      badges: null,
      show: false,
      headers: {},
      idMediaAsset: null,
      urlMediaAsset: null,
      name: '',
      backgroundColor: '#ffffff',
      textColor: '#000000',
      userData: getUserData(),
      nameState: null,
      isActive: true,
      imageBol: false,
      imageFile: null,
      deleteFile: [],
      typeMediaAsset: 'MP3',
      mediaAssetTypes: {
        MP3: 'MP3',
        MOV: 'MOV',
        MP4: 'MP4',
        HLS: 'HLS',
        DASH: 'DASH',
      },
      typesDRM: null,
      drmTypes:
      {
        null: this.$t('sinDRM'),
        FAI: 'FairPlay',
        WID: 'WideVine',
        PLA: 'PlayReady',
      },
      typeCOD: 'H264',
      codecTypes: {
        H264: 'H264',
        H265: 'H265',
        MPEG: 'MPEG',
      },
      quality: 'No_Quality',
      qualityOptions: getQualityOptions(),
      drmLicenseServUrl: null,
      drmFairplayCertUrl: null,

    }
  },
  watch: {
    typeMediaAsset() {
      this.$nextTick(() => {
        if (this.disabledOptions(this.typesDRM)) this.typesDRM = null
      })
    },

  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }
    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    if (this.updateAction) { this.fillAsset() } else { this.clearAsset() }
  },
  methods: {
    fillAsset() {
      const {
        id, url, type, drmType, codec, maxQuality, isActive, drmLicenseServUrl, drmFairplayCertUrl,
      } = this.mediaAsset
      this.drmLicenseServUrl = drmLicenseServUrl
      this.drmFairplayCertUrl = drmFairplayCertUrl
      this.idMediaAsset = id
      this.urlMediaAsset = url
      this.typeMediaAsset = type
      this.typesDRM = drmType
      this.typeCOD = codec
      this.isActive = isActive
      this.quality = maxQuality != null ? maxQuality : 'No_Quality'
    },
    clearAsset() {
      this.idMediaAsset = null
      this.urlMediaAsset = null
      this.drmLicenseServUrl = null
      this.drmFairplayCertUrl = null
      this.typeMediaAsset = 'MP3'
      this.typesDRM = null
      this.isActive = false
      this.typeCOD = 'H264'
      this.quality = 'No_Quality'
    },
    updateMutate() {
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const { headers } = this
      const query = `
      mutation(
          
          $url:String,
          $drmLicenseServUrl:String,
          $drmFairplayCertUrl:String,
          $type:ContentsMediaAssetTypeChoices,
          $drmType:ContentsMediaAssetDrmTypeChoices,
          $maxQuality: ContentsMediaAssetMaxQualityChoices,
          $isActive:Boolean,
          $codec:ContentsMediaAssetCodecChoices,
          $id:ID!,

      ){
        updateMediaAsset(id:$id,input:{
          url${this.language}:$url,
          type:$type,
          drmLicenseServUrl:$drmLicenseServUrl,
          drmFairplayCertUrl:$drmFairplayCertUrl,
          drmType:$drmType,maxQuality:$maxQuality,
          codec:$codec, isActive:$isActive,
          
        }){
          mediaAsset{
            id
            drmType
          }
        }
      }`
      const variables = {
        id: this.idMediaAsset,
        url: this.urlMediaAsset,
        type: this.typeMediaAsset,
        drmType: this.typesDRM,
        drmLicenseServUrl: this.drmLicenseServUrl,
        drmFairplayCertUrl: this.drmFairplayCertUrl,
        maxQuality: this.quality !== 'No_Quality' ? this.quality : null,
        isActive: this.isActive,
        codec: this.typeCOD,
      }
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      axios.post('', data, { headers }, config)
        .then(result => {
          messageError(result, this)
          showToast(this.$t('success'), 1, this)

          this.$emit('refresh')
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    createMutate() {
      const headers = {
        'Accept-Language': camelToKebab(this.language),
        'Content-Language': camelToKebab(this.language),
      }
      const resourceId = utf8ToB64(`id:${this.$route.params.id}`)

      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
      mutation(
          $url:String,
          $urlBase:String!,
          $drmLicenseServUrl:String,
          $drmFairplayCertUrl:String,
          $type:ContentsMediaAssetTypeChoices,
          $drmType:ContentsMediaAssetDrmTypeChoices,
          $maxQuality: ContentsMediaAssetMaxQualityChoices,
          $isActive:Boolean,
          $codec:ContentsMediaAssetCodecChoices,
      ){
        createMediaAsset(input:{
          url${this.language}:$url,
          url:$urlBase,
          type:$type,
          drmLicenseServUrl:$drmLicenseServUrl,
          drmFairplayCertUrl:$drmFairplayCertUrl,
          drmType:$drmType,maxQuality:$maxQuality,
          codec:$codec, isActive:$isActive,

          client: "${this.userData.profile.client.id}"
          resource:"${resourceId}",
        }){
          mediaAsset{
            id
            drmType
          }
        }
      }`
      const variables = {
        url: this.urlMediaAsset,
        urlBase: this.urlMediaAsset,
        type: this.typeMediaAsset,
        drmType: this.typesDRM,
        drmLicenseServUrl: this.drmLicenseServUrl,
        drmFairplayCertUrl: this.drmFairplayCertUrl,
        maxQuality: this.quality !== 'No_Quality' ? this.quality : null,
        isActive: this.isActive,
        codec: this.typeCOD,
      }
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      axios.post('', data, { headers }, config)
        .then(result => {
          messageError(result, this)
          showToast(this.$t('success'), 1, this)

          this.$emit('refresh')
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
    },
    deactiveDrmType() {
      if (this.typeMediaAsset !== 'HLS' && this.typeMediaAsset !== 'DASH') {
        this.typesDRM = null
        return true
      }
      return false
    },
    disabledOptions(types) {
      if (this.typeMediaAsset === 'HLS') {
        if (types === 'PLA' || types === 'WID' || types === 'PLA') { return true }
      }
      if (this.typeMediaAsset === 'DASH') {
        if (types === 'FAI') { return true }
      }
      return false
    },

  },
}
</script>
<style lang="scss" scoped>
#badge-view .edit:hover {
  color: rgb(0, 83, 7);
  cursor: pointer;
}

#badge-view .trash:hover {
  color: rgb(109, 0, 0);
  cursor: pointer;
}

#badge-view .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#badge-view .card-body h4 {
  font-size: 1.286rem !important;
}

#badge-view .col-form-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

#badge-view .fly-image-input {
  max-width: 100%;
}

#badge-view .base-color-input {
  display: block;
  border-radius: 15px;
  border: solid 1px;
  border-color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#badge-view .base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#badge-view .div {
  display: inline-block;
}

#badge-view .inputColor {
  visibility: hidden;
}

#badge-view .placeholder_photo {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#badge-view .placeholder_photo:hover {
  background: #e0e0e0;
}

#badge-view .file-input {
  display: none;
}

#badge-view .cBox {
  align-items: center;
}

#badge-view .custom-control-label {
  font-size: 15px;
}

#badge-view .scroll {
  overflow: hidden;
  flex-wrap: nowrap !important;

}

#badge-view .list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

#badge-view .contain {
  object-fit: contain;
  cursor: pointer;
}
</style>
