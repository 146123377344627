<template>
  <section id="editarResources">
    <b-tabs
      v-if="mostrarDatos"
      pills
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>{{ $t("resources.resourceInfo") }}</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showResource"
              :opacity="1.00"
              :blur="'2px'"
              rounded="lg"
            >
              <b-card>
                <b-card-header>
                  <b-col md="4">
                    <b-card-title>{{ $t("resources.resourceData") }}</b-card-title>
                  </b-col>
                  <!-- Si la plataforma permite transcodificación y el recurso se puede transcodificar, mostramos el botón de transcodificar recurso -->
                  <b-col
                    v-if="showMsgStateTranscode"
                    md="2"
                    style="text-align: end;"
                  >
                    <transcoding-info
                      :show-msg-state-transcode="showMsgStateTranscode"
                      :transcoding-state="transcodingState"
                      :id-transcoding="idTranscoding"
                      :could-transcode="couldTranscode"
                      :id-resource="$route.params.id"
                    />
                  </b-col>
                  <b-col
                    v-if="hasActiveMediaAsset"
                    :md="showMsgStateTranscode ? '4' : '6'"
                    style="text-align: end;"
                  >
                    <b-badge variant="light-warning">
                      <i
                        size="12"
                        class="fa fa-warning text-warning align-middle mr-50"
                      />
                      <span class="font-weight-bolder align-middle"> {{ $t("resources.hasMediaAsset") }}</span>
                    </b-badge>
                  </b-col>
                  <b-col
                    md="2"
                    style="text-align: end;"
                  >
                    <b-button
                      class="m-2"
                      variant="success"
                      @click="validationFormInfo"
                    >
                      {{ $t("dataGeneric.save") }}
                    </b-button>
                  </b-col>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col
                      lg="12"
                      class="ml-2 mb-2"
                    >
                      <validation-observer ref="accountRules">
                        <b-form>
                          <validation-provider
                            v-slot="{ errors }"
                            name="name"
                            :rules="'required'"
                          >
                            <b-form-group
                              class="mb-2 mr-1"
                              :label="$t('resources.resourceName')"
                              :invalid-feedback="$t('required')"
                              :state="errors.length > 0 ? false : null"
                            >
                              <b-form-input
                                v-model="resourceName"
                                :placeholder="$t('resources.resourceName')"
                                :state="errors.length > 0 ? false : null"
                                class="mr-1"
                              />
                            </b-form-group>
                          </validation-provider>
                          <b-form-group
                            class="mb-2 mr-1"
                            :label="$t('resources.resourceDescription')"
                          >
                            <b-form-input
                              v-model="resourceDescription"
                              :placeholder="$t('dataGeneric.description')"
                              class="mr-1"
                            />
                          </b-form-group>
                          <b-form-group
                            v-if="subgroups && hasSubgroups && checkPermissions('users.add_subgroup')"
                            :label="$t('Subgroup')"
                          >
                            <v-select
                              id="subgroup"
                              v-model="subgroup"
                              :options="subgroups"
                              label="name"
                              :placeholder="$t('SinGrupoUsuario')"
                              aria-autocomplete="new-password"
                              autocomplete="new-password"
                            />
                          </b-form-group>
                          <!-- DEEPLINK -->

                          <template v-if="resourceType === 'DEE' && language == base">
                            <h5>{{ $t("Configuration") }} DeepLink</h5>
                            <b-form-row>
                              <b-col
                                lg="6"
                                md="6"
                                sm="12"
                              >
                                <b-form-group label="DeepLink Android *">
                                  <validation-provider
                                    v-slot="validationContext"
                                    name="android"
                                    rules="required|url"
                                  >
                                    <b-form-input
                                      id="android"
                                      v-model="android"
                                      :state="validationContext.errors.length > 0 ? false : null"
                                      placeholder="DeepLink Android"
                                    />
                                    <small class="text-danger">{{
                                      validationMedia(validationContext)
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col
                                lg="6"
                                md="6"
                                sm="12"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="androidTV"
                                  rules="required"
                                >
                                  <b-form-group
                                    label="DeepLink Android TV *"
                                    :invalid-feedback="$t('required')"
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    <b-form-input
                                      id="androidTV"
                                      v-model="androidTV"
                                      :state="errors.length > 0 ? false : null"
                                      placeholder="DeepLink Android TV"
                                    />
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                            </b-form-row>
                            <b-form-row>
                              <b-col
                                lg="6"
                                md="6"
                                sm="12"
                              >
                                <b-form-group label="DeepLink iOS *">
                                  <validation-provider
                                    v-slot="validationContext"
                                    name="ios"
                                    rules="required|url"
                                  >
                                    <b-form-input
                                      id="ios"
                                      v-model="ios"
                                      :state="validationContext.errors.length > 0 ? false : null"
                                      placeholder="DeepLink iOS"
                                    />
                                    <small class="text-danger">{{
                                      validationMedia(validationContext) }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col
                                lg="6"
                                md="6"
                                sm="12"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="iosTV"
                                  rules="required"
                                >
                                  <b-form-group
                                    label="DeepLink tvOS *"
                                    :invalid-feedback="$t('required')"
                                  >
                                    <b-form-input
                                      id="iosTV"
                                      v-model="iosTV"
                                      :state="errors.length > 0 ? false : null"
                                      placeholder="DeepLink tvOS"
                                    />
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                            </b-form-row>
                            <b-form-row>
                              <b-col
                                lg="6"
                                md="6"
                                sm="12"
                              >
                                <b-form-group label="DeepLink Web App *">
                                  <validation-provider
                                    v-slot="validationContext"
                                    name="webApp"
                                    rules="required|url"
                                  >
                                    <b-form-input
                                      id="webApp"
                                      v-model="webApp"
                                      :state="validationContext.errors.length > 0 ? false : null"
                                      placeholder="DeepLink Web App"
                                    />
                                    <small class="text-danger">{{
                                      validationMedia(validationContext)
                                    }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                          </template>
                          <!-- VIDEO -->

                          <template v-else-if="resourceType === 'VID'">
                            <h5>{{ $t("resources.resourceMedia") }}</h5>
                            <b-form-row>
                              <b-col
                                v-if="videoResourceType !== 'BUK'"
                                cols="5"
                              >
                                <b-form-group
                                  v-if="language == base"
                                  class="mb-2 "
                                  :label="$t('resources.originMedia')"
                                >
                                  <b-form-select
                                    v-model="videoResourceType"
                                    class="mr-1"
                                    :options="videoResourceTypeOption"
                                    :disabled="disabledFieldsStream()"
                                  />
                                </b-form-group>

                                <div v-if="videoResourceType === 'DIR'">
                                  <b-form>
                                    <b-form-group :label="$t('liveVid') + ' *'">
                                      <validation-provider
                                        v-slot="validationContext"
                                        name="directo"
                                        rules="required|url"
                                      >
                                        <b-form-input
                                          id="directo"
                                          v-model="archivoUrl"
                                          :state="validationContext.errors.length > 0 ? false : null"
                                          :placeholder="$t('URL del directo video')"
                                          :disabled="disabledFieldsStream()"
                                        />
                                        <small class="text-danger">{{ validationMedia(validationContext) }}
                                        </small>
                                      </validation-provider>
                                    </b-form-group>
                                  </b-form>

                                  <b-form-group
                                    v-if="language == base"
                                    :label="$t('usuarioStream')"
                                  >
                                    <b-form-input
                                      id="userStream"
                                      v-model="userStream"
                                      autocomplete="new-password"
                                      :placeholder="$t('usuarioStream')"
                                      :disabled="disabledFieldsStream()"
                                    />
                                  </b-form-group>

                                  <b-form-group :label="$t('inputStream')">
                                    <b-form-input
                                      id="inputStream"
                                      v-model="inputStream"
                                      autocomplete="new-password"
                                      :placeholder="$t('inputStream')"
                                      :disabled="disabledFieldsStream()"
                                    />
                                  </b-form-group>

                                  <b-form-group
                                    v-if="language == base"
                                    :label="$t('passStream')"
                                  >
                                    <b-form-input
                                      id="passStream"
                                      v-model="passStream"
                                      autocomplete="new-password"
                                      type="password"
                                      :placeholder="$t('passStream')"
                                      :disabled="disabledFieldsStream()"
                                    />
                                  </b-form-group>
                                  <b-form-group
                                    v-if="language == base"
                                    label="URL thumbnail track"
                                  >
                                    <validation-provider
                                      v-slot="validationContext"
                                      name="thumbnailTrackUrl"
                                      rules="url"
                                    >
                                      <b-form-input
                                        id="thumbnailTrackUrl"
                                        v-model="thumbnailTrackUrl"
                                        required
                                        autocomplete="new-password"
                                        :state="validationContext.errors.length > 0 ? false : null"
                                        placeholder="Thumbnail track"
                                      />
                                      <small class="text-danger">{{ validationMedia(validationContext) }}
                                      </small>
                                    </validation-provider>
                                  </b-form-group>
                                </div>

                                <div v-else-if="videoResourceType === 'AUD'">
                                  <b-form>
                                    <validation-provider
                                      v-slot="validationContext"
                                      name="directo"
                                      rules="required|url"
                                    >
                                      <b-form-group :label="$t('liveAud') + ' *'">
                                        <b-form-input
                                          id="directo"
                                          v-model="archivoUrl"
                                          :state="validationContext.errors.length > 0 ? false : null"
                                          :placeholder="$t('URL del directo audio')"
                                        />
                                        <small class="text-danger">{{ validationMedia(validationContext) }}
                                        </small>
                                      </b-form-group>
                                    </validation-provider>
                                  </b-form>

                                  <b-form-group
                                    v-if="language == base"
                                    :label="$t('usuarioStream')"
                                  >
                                    <b-form-input
                                      id="userStream"
                                      v-model="userStream"
                                      autocomplete="new-password"
                                      :placeholder="$t('usuarioStream')"
                                    />
                                  </b-form-group>
                                  <b-form-group :label="$t('inputStream')">
                                    <b-form-input
                                      id="inputStream"
                                      v-model="inputStream"
                                      autocomplete="new-password"
                                      :placeholder="$t('inputStream')"
                                    />
                                  </b-form-group>
                                  <b-form-group
                                    v-if="language == base"
                                    :label="$t('passStream')"
                                  >
                                    <b-form-input
                                      id="passStream"
                                      v-model="passStream"
                                      autocomplete="new-password"
                                      type="password"
                                      :placeholder="$t('passStream')"
                                    />
                                  </b-form-group>
                                </div>

                                <div v-else-if="videoResourceType === 'URL'">
                                  <b-form-group :label="$t('URL fichero video') + ' *'">
                                    <validation-provider
                                      v-slot="validationContext"
                                      name="urlVideo"
                                      rules="required|url"
                                    >
                                      <b-form-input
                                        id="urlVideo"
                                        v-model="archivoUrl"
                                        :state="validationContext.errors.length > 0 ? false : null"
                                        :placeholder="$t('URL fichero video')"
                                      />
                                      <small class="text-danger">{{ validationMedia(validationContext) }}
                                      </small>
                                    </validation-provider>
                                  </b-form-group>
                                  <b-form-group
                                    v-if="language == base"
                                    label="URL thumbnail track"
                                  >
                                    <validation-provider
                                      v-slot="validationContext"
                                      name="thumbnailTrackUrl"
                                      rules="url"
                                    >
                                      <b-form-input
                                        id="thumbnailTrackUrl"
                                        v-model="thumbnailTrackUrl"
                                        required
                                        autocomplete="new-password"
                                        :state="validationContext.errors.length > 0 ? false : null"
                                        placeholder="Thumbnail track"
                                      />
                                      <small class="text-danger">{{ validationMedia(validationContext) }}
                                      </small>
                                    </validation-provider>
                                  </b-form-group>
                                  <b-button
                                    class="mt-1 h-25 py-1"
                                    variant="primary"
                                    @click="$refs['subtitleModal'].show()"
                                  >
                                    {{ $t('subtitles.subtitlesEditor') }}
                                  </b-button>
                                </div>
                                <div v-else-if="videoResourceType === 'PRE'">
                                  <b-form-group :label="$t('URL del vídeo previo') + ' *'">
                                    <validation-provider
                                      v-slot="validationContext"
                                      name="urlPre"
                                      rules="required|url"
                                    >
                                      <b-form-input
                                        id="urlPre"
                                        v-model="archivoUrl"
                                        :state="validationContext.errors.length > 0 ? false : null"
                                        :placeholder="$t('URL del vídeo previo')"
                                      />
                                      <small class="text-danger">{{ validationMedia(validationContext) }}
                                      </small>
                                    </validation-provider>
                                  </b-form-group>
                                  <b-form-group
                                    v-if="language == base"
                                    label="URL thumbnail track"
                                  >
                                    <validation-provider
                                      v-slot="validationContext"
                                      name="thumbnailTrackUrl"
                                      rules="url"
                                    >
                                      <b-form-input
                                        id="thumbnailTrackUrl"
                                        v-model="thumbnailTrackUrl"
                                        required
                                        autocomplete="new-password"
                                        :state="validationContext.errors.length > 0 ? false : null"
                                        placeholder="Thumbnail track"
                                      />
                                      <small class="text-danger">{{ validationMedia(validationContext) }}
                                      </small>
                                    </validation-provider>
                                  </b-form-group>
                                </div>

                                <div v-else-if="videoResourceType === 'BRU'">
                                  <b-form-row v-if="language == base">
                                    <b-col cols="12">
                                      <validation-provider
                                        v-slot="{ errors }"
                                        name="idObra"
                                        rules="required"
                                      >
                                        <b-form-group
                                          :label="$t('workId') + ' *'"
                                          :invalid-feedback="$t('required')"
                                          :state="errors.length > 0 ? false : null"
                                        >
                                          <b-form-input
                                            id="idObra"
                                            v-model="idObra"
                                            :state="errors.length > 0 ? false : null"
                                            :placeholder="$t('workId')"
                                          />
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                  </b-form-row>
                                </div>
                                <div v-else-if="videoResourceType === 'BRD'">
                                  <b-form-row v-if="language == base">
                                    <b-col cols="12">
                                      <validation-provider
                                        v-slot="{ errors }"
                                        name="archivoUrl"
                                        rules="required"
                                      >
                                        <b-form-group
                                          :label="$t('drmBru') + ' *'"
                                          :invalid-feedback="$t('required')"
                                          :state="errors.length > 0 ? false : null"
                                        >
                                          <b-form-input
                                            id="archivoUrl"
                                            v-model="archivoUrl"
                                            :state="errors.length > 0 ? false : null"
                                            :placeholder="$t('drmBru')"
                                          />
                                        </b-form-group>
                                      </validation-provider>
                                    </b-col>
                                  </b-form-row>
                                </div>

                                <div v-else-if="videoResourceType === 'AUR'">
                                  <validation-provider
                                    v-slot="validationContext"
                                    name="urlAudio"
                                    rules="required|url"
                                  >
                                    <b-form-group :label="$t('resources.audioUrl') + ' *'">
                                      <b-form-input
                                        id="urlAudio"
                                        v-model="archivoUrl"
                                        :state="validationContext.errors.length > 0 ? false : null"
                                        :placeholder="$t('resources.audioUrl')"
                                      />
                                      <small class="text-danger">{{ validationMedia(validationContext) }}
                                      </small>
                                    </b-form-group>
                                  </validation-provider>
                                </div>
                              </b-col>
                              <b-col
                                v-if="videoResourceType !== 'AUR' && videoResourceType !== 'BUK' && videoResourceType !== 'BRD'"
                                class="mb-2 ml-3 embed-responsive embed-responsive-16by9"
                                cols="6"
                              >
                                <video
                                  id="demo"
                                  ref="fileInputTrVid"
                                  class="
                                      video-js
                                      vjs-default-skin vjs-big-play-centered
                                      embed-responsive-item"
                                  data-setup="{&quot;liveui&quot;: true}"
                                  controls
                                  :poster="archivoUrlPreview.includes('mp3')
                                    ? require('@/assets/images/icons/audio.svg') : ''"
                                  @loadeddata="videoMaker()"
                                  @loadstart="videoMaker()"
                                >
                                  <source
                                    :src="archivoUrlPreview"
                                    :type="archivoUrlPreview.includes('m3u8')
                                      ? 'application/x-mpegURL'
                                      : archivoUrlPreview.includes('mp4')
                                        ? 'video/mp4'
                                        : 'audio/mpeg'"
                                  >
                                </video>
                                <b-form-group
                                  v-if="language == base"
                                  label="URL thumbnail track"
                                >
                                  <validation-provider
                                    v-slot="validationContext"
                                    name="thumbnailTrackUrl"
                                    rules="url"
                                  >
                                    <b-form-input
                                      id="thumbnailTrackUrl"
                                      v-model="thumbnailTrackUrl"
                                      required
                                      autocomplete="new-password"
                                      :state="validationContext.errors.length > 0 ? false : null"
                                      placeholder="Thumbnail track"
                                    />
                                    <small class="text-danger">{{ validationMedia(validationContext) }}
                                    </small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>

                              <b-col
                                v-if="videoResourceType !== 'BRU' && videoResourceType !== 'BRD'"
                                cols="12"
                              >
                                <video
                                  v-if="archivoUrl && videoResourceType === 'AUR'"
                                  id="demo"
                                  ref="fileInputTrVid"
                                  class="
                                        video-js
                                        vjs-default-skin vjs-big-play-centered
                                        base-image-video embed-responsive-item"
                                  data-setup="{&quot;liveui&quot;: true}"
                                  controls
                                  :poster="archivoUrlPreview.includes('mp3')
                                    ? require('@/assets/images/icons/audio.svg')
                                    : ''"
                                  @loadeddata="videoMaker()"
                                  @loadstart="videoMaker()"
                                >
                                  <source
                                    :src="archivoUrlPreview"
                                    :type="archivoUrlPreview.includes('m3u8')
                                      ? 'application/x-mpegURL'
                                      : archivoUrlPreview.includes('mp4')
                                        ? 'video/mp4'
                                        : 'audio/mpeg'"
                                  >
                                </video>
                                <div v-if="videoResourceType === 'BUK'">
                                  <div v-if="datosVideo.fileUrl">
                                    <view-file-modal
                                      :subtitle="true"
                                      :datos="datosVideo"
                                    />
                                  </div>
                                  <b-col cols="6">
                                    <div class="d-flex mt-2 mb-2">
                                      <b-form-input
                                        v-model="fileName"
                                        placeholder="Archivo"
                                        disabled
                                      />
                                      <b-button
                                        class="ml-2"
                                        @click="$refs['MediaSelector'].show()"
                                      >
                                        {{ $t('select') }}
                                      </b-button>
                                    </div>
                                    <b-form-group
                                      v-if="language == base && videoResourceType === 'BUK'"
                                      label="URL thumbnail track"
                                    >
                                      <validation-provider
                                        v-slot="validationContext"
                                        name="thumbnailTrackUrl"
                                        rules="url"
                                      >
                                        <b-form-input
                                          id="thumbnailTrackUrl"
                                          v-model="thumbnailTrackUrl"
                                          required
                                          autocomplete="new-password"
                                          :state="validationContext.errors.length > 0 ? false : null"
                                          placeholder="Thumbnail track"
                                        />
                                        <small class="text-danger">{{ validationMedia(validationContext) }}
                                        </small>
                                      </validation-provider>
                                    </b-form-group>
                                  </b-col>
                                </div>
                              </b-col>
                            </b-form-row>
                          </template>
                          <!-- MÁSINFO -->

                          <template v-else-if="resourceType === 'PLN'">
                            <b-form-row>
                              <b-col
                                v-if="language == base"
                                md="6"
                                sm="12"
                              >
                                <b-form-group
                                  :label="$t('resources.scenarioName')"
                                  label-for="escenarioName"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="escenarioName"
                                    rules="required"
                                  >
                                    <b-form-input
                                      id="escenarioName"
                                      v-model="escenarioName"
                                      :state="errors.length > 0 ? false : null"
                                      :placeholder="$t('resources.scenarioName')"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>

                              <b-col
                                md="6"
                                sm="12"
                              >
                                <b-form-group
                                  :label="$t('resources.scenarioTitle')"
                                  label-for="escenarioTitulo"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="escenarioTitulo"
                                  >
                                    <b-form-input
                                      id="escenarioTitulo"
                                      v-model="escenarioTitulo"
                                      :state="errors.length > 0 ? false : null"
                                      :placeholder="$t('resources.scenarioTitle')"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>

                              <b-col
                                md="6"
                                sm="12"
                              >
                                <b-form-group
                                  :label="$t('resources.scenarioText')"
                                  label-for="description"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="description"
                                  >
                                    <b-form-textarea
                                      v-model="textoMasInfo"
                                      :state="errors.length > 0 ? false : null"
                                      :placeholder="$t('resources.scenarioTitle')"
                                      rows="3"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <image-element-vue
                                :image-origin="imagePlnData"
                                :label-info="$t('infoImage.mInfo')"
                                :image-type="'imagePlnData'+ language"
                                :label="$t('Image')"
                                @saveImage="loadImage"
                              />
                            </b-form-row>
                          </template>
                          <!-- CUESTIONARIO -->

                          <template v-else-if="resourceType === 'TST'">
                            <b-form-row>
                              <b-col
                                md="6"
                                sm="12"
                              >
                                <b-form-group :label="$t('resources.introduction')">
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="introTest"
                                  >
                                    <b-form-textarea
                                      v-model="introTest"
                                      if="introTest"
                                      :state="errors.length > 0 ? false : null"
                                      :placeholder="$t('resources.questionnaireIntro')"
                                      rows="3"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col
                                md="6"
                                sm="12"
                              >
                                <b-form-group
                                  :label="$t('resources.question')"
                                  label-for="question"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="question"
                                    :rules="'required'"
                                  >
                                    <b-form-input
                                      id="question"
                                      v-model="question"
                                      :state="errors.length > 0 ? false : null"
                                      :placeholder="$t('resources.questionPlace')"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col
                                md="6"
                                sm="12"
                              >
                                <b-form-group
                                  :label="$t('resources.feedbackOK')"
                                  label-for="feedbackOk"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="feedbackOk"
                                  >
                                    <b-form-textarea
                                      id="feedbackOk"
                                      v-model="fedOk"
                                      :state="errors.length > 0 ? false : null"
                                      :placeholder="$t('resources.msgFeedbackKO')"
                                      rows="3"
                                    />
                                    <small class="text-danger">{{
                                      errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <b-col
                                md="6"
                                sm="12"
                              >
                                <b-form-group
                                  :label="$t('resources.feedbackKO')"
                                  label-for="feedbackError"
                                >
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="feedbackError"
                                  >
                                    <b-form-textarea
                                      id="feedbackError"
                                      v-model="fedError"
                                      :state="errors.length > 0 ? false : null"
                                      :placeholder="$t('resources.msgFeedbackKO')"
                                      rows="3"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                  </validation-provider>
                                </b-form-group>
                              </b-col>
                              <image-element-vue
                                :image-origin="imageTestData"
                                :image-type="'imageTestData'+language"
                                :label="$t('resources.questionnaireImg')"
                                @saveImage="loadImage"
                              />
                              <image-element-vue
                                :image-origin="backgroundTestData"
                                :image-type="'backgroundTestData'+language"
                                :label-info="$t('infoImage.backgQuest')"
                                :label="$t('resources.questionnaireBckg')"
                                @saveImage="loadImage"
                              />
                            </b-form-row>
                            <b-form-row>
                              <b-col>
                                <b-card
                                  id="answers-card"
                                  :title="$t('resources.answers')"
                                  class="shadow p-3 rounded"
                                >
                                  <draggable
                                    v-if="language == base"
                                    v-model="answers"
                                    handle=".handle"
                                  >
                                    <b-card
                                      v-for="(answer, index) in answers"
                                      :key="'card_' + index"
                                      :border-variant="answer.isTheCorrect ? 'success' : ''"
                                      :bg-variant="answer.isTheCorrect ? 'success' : ''"
                                      no-body
                                      class="answer-box"
                                    >
                                      <b-row no-gutters>
                                        <b-col
                                          md="1"
                                          lg="1"
                                        >
                                          <feather-icon
                                            size="15"
                                            icon="AlignJustifyIcon"
                                            class="handle"
                                          />
                                        </b-col>
                                        <b-col
                                          class="answer-num"
                                          md="1"
                                          lg="1"
                                        >
                                          <span>{{ index + 1 }}.</span>
                                        </b-col>
                                        <b-col
                                          md="8"
                                          lg="8"
                                        >
                                          <b-form-group>
                                            <validation-provider
                                              v-slot="{ errors }"
                                              :name="'input_' + index"
                                              :rules="'required'"
                                            >
                                              <b-form-input
                                                :id="'input_' + index"
                                                v-model="answer.response"
                                                :state="errors.length > 0 ? false : null"
                                                :placeholder="$t('resources.createAnswer')"
                                              />
                                              <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                          </b-form-group>
                                        </b-col>
                                        <b-col
                                          v-if="language == base"
                                          md="2"
                                          lg="2"
                                          class="d-flex justify-content-center"
                                        >
                                          <b-form-group :label-for="'checkbox_' + index">
                                            <b-form-checkbox
                                              :id="'checkbox_' + index"
                                              v-model="answer.isTheCorrect"
                                              name="anuncios"
                                              :switch="true"
                                              @change="checkOtherAnswers(index)"
                                            />
                                          </b-form-group>
                                          <div>
                                            <b-button
                                              v-if="answers.length > 2"
                                              :disabled="answer.isTheCorrect ? true : false"
                                              class="btn-icon rounded-circle"
                                              variant="danger"
                                              @click="dropSpecificQuestion(index)"
                                            >
                                              <feather-icon
                                                icon="XCircleIcon"
                                                size="17"
                                              />
                                            </b-button>
                                          </div>
                                        </b-col>
                                      </b-row>
                                    </b-card>
                                  </draggable>
                                  <div v-else>
                                    <b-card
                                      v-for="(answer, index) in answers"
                                      :key="'card_' + index"
                                      :border-variant="answer.isTheCorrect ? 'success' : ''"
                                      :bg-variant="answer.isTheCorrect ? 'success' : ''"
                                      no-body
                                      class="answer-box"
                                    >
                                      <b-row no-gutters>
                                        <b-col
                                          md="1"
                                          lg="1"
                                        />
                                        <b-col
                                          class="answer-num"
                                          md="1"
                                          lg="1"
                                        >
                                          <span>{{ index + 1 }}.</span>
                                        </b-col>
                                        <b-col
                                          md="8"
                                          lg="8"
                                        >
                                          <b-form-group>
                                            <validation-provider
                                              v-slot="{ errors }"
                                              :name="'input_' + index"
                                              :rules="'required'"
                                            >
                                              <b-form-input
                                                :id="'input_' + index"
                                                v-model="answer.response"
                                                :state="errors.length > 0 ? false : null"
                                                :placeholder="$t('resources.createAnswer')"
                                              />
                                              <small class="text-danger">{{ errors[0] }}</small>
                                            </validation-provider>
                                          </b-form-group>
                                        </b-col>
                                        <b-col
                                          v-if="language == base"
                                          md="2"
                                          lg="2"
                                          class="d-flex justify-content-center"
                                        >
                                          <b-form-group :label-for="'checkbox_' + index">
                                            <b-form-checkbox
                                              :id="'checkbox_' + index"
                                              v-model="answer.isTheCorrect"
                                              name="anuncios"
                                              :switch="true"
                                              @change="checkOtherAnswers(index)"
                                            />
                                          </b-form-group>
                                          <div>
                                            <b-button
                                              v-if="answers.length > 2"
                                              :disabled="answer.isTheCorrect ? true : false"
                                              class="btn-icon rounded-circle"
                                              variant="danger"
                                              @click="dropSpecificQuestion(index)"
                                            >
                                              <feather-icon
                                                icon="XCircleIcon"
                                                size="17"
                                              />
                                            </b-button>
                                          </div>
                                        </b-col>
                                      </b-row>
                                    </b-card>
                                  </div>

                                  <b-button
                                    v-if="answers.length < 4"
                                    @click="addNewAnswer"
                                  >
                                    {{ $t("resources.answerAdd") }}
                                  </b-button>

                                  <b-button
                                    v-if="answers.length > 2"
                                    class="btn btn-danger ml-2"
                                    :disabled="answers[answers.length - 1].isTheCorrect"
                                    @click="dropLastAnswer"
                                  >
                                    {{ $t("resources.dropLastAnswer") }}
                                  </b-button>
                                </b-card>
                                <b-alert
                                  v-model="showInfoMaxAnswers"
                                  variant="success"
                                  dismissible
                                >
                                  <h4 class="alert-heading">
                                    Info
                                  </h4>
                                  <div class="alert-body">
                                    <span>{{ $t("resources.maxAnswers") }}</span>
                                  </div>
                                </b-alert>
                              </b-col>
                            </b-form-row>
                          </template>
                          <!-- COMPRA -->
                          <template v-else-if="(resourceType === 'BUY' || resourceType === 'CAR' || resourceType === 'A2B' || resourceType === 'A2C') && language == base">
                            <b-form-row>
                              <b-col cols="12">
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="product"
                                  rules="required"
                                >
                                  <b-form-group
                                    :label="$t('producto') + ' *'"
                                    :invalid-feedback="$t('required')"
                                    :state="errors.length > 0 ? false : null"
                                  >
                                    <div class="d-flex">
                                      <b-form-input
                                        id="product"
                                        v-model="productName"
                                        disabled
                                        :state="errors.length > 0 ? false : null"
                                        :placeholder="$t('producto')"
                                      />
                                      <b-button
                                        variant="primary"
                                        class="ml-2"
                                        @click="$refs['product-modal'].show()"
                                      >
                                        {{ $t('select') }}
                                      </b-button>
                                    </div>
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                            </b-form-row>
                            <b-form-row v-if="(resourceType === 'A2B' || resourceType === 'A2C') && language == base">
                              <b-col
                                md="6"
                                sm="12"
                              >
                                <validation-provider
                                  v-slot="{ errors }"
                                  name="product-reference"
                                  rules="required"
                                >
                                  <b-form-group
                                    :invalid-feedback="$t('required')"
                                    label-for="product-reference"
                                  >
                                    <span>
                                      {{ $t('resources.productReference') + ' *' }}
                                    </span>
                                    <b-form-input
                                      id="product-reference"
                                      v-model="productReference"
                                      class="mt-50"
                                      :state="errors.length > 0 ? false : null"
                                      :placeholder="$t('resources.productReference')"
                                    />
                                  </b-form-group>
                                </validation-provider>
                              </b-col>
                            </b-form-row>
                          </template>
                        </b-form>
                      </validation-observer>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab v-if="resourceType === 'VID'">
        <template #title>
          <feather-icon icon="FilmIcon" />
          <span>Media Asset</span>
        </template>
        <b-row class="match-height mt-2">
          <b-col>
            <b-overlay
              variant="transparent"
              :show="showResource"
              :opacity="1.00"
              :blur="'2px'"
              rounded="lg"
            >
              <b-card>
                <b-card-header class="mb-2">
                  <b-card-title>{{ $t("MediaAsset") }}</b-card-title>
                  <div
                    v-if="isSuperuser"
                    style="text-align: end;"
                  >
                    <b-button
                      variant="primary"
                      @click="createMediaAsset"
                    >
                      <feather-icon
                        icon="PlusCircleIcon"
                        class="ml-50"
                        size="15"
                      />
                      {{ $t("AddMediaAsset") }}
                    </b-button>
                  </div>
                </b-card-header>
                <b-card-body>
                  <!-- <b-row class="mb-2">
                    <b-col>
                      <b-form-input
                        v-model="search"
                        :placeholder="$t('Search')"
                        @keyup="inputSearch"
                      />
                    </b-col>
                    <b-col md="1">
                      <b-button
                        variant="primary"
                        @click="searching"
                      >
                        {{ $t('Search') }}
                      </b-button>
                    </b-col>
                  </b-row> -->
                  <b-row class="d-flex flex-wrap">
                    <b-col>
                      <div class="content-table">
                        <b-table
                          :items="searchAsset"
                          :fields="tableColumns"
                        >
                          <template #cell(url)="data">
                            <b-row style="align-items: center;">
                              <b-col id="acortarPadre">
                                <h5
                                  v-if="!isEmpty(data.item.node.url)"
                                  id="acortar"
                                >
                                  {{ data.item.node.url }}
                                </h5>
                                <span
                                  v-else
                                  id="acortar"
                                  class="text-muted"
                                >
                                  {{ $t('noTranslate') }}
                                </span>
                              </b-col>
                            </b-row>
                          </template>

                          <template #cell(typesMA)="data">
                            <div class="text-nowrap">
                              {{ mediaAssetTypes[data.item.node.type] }}
                            </div>
                          </template>

                          <template #cell(codec)="data">
                            <div class="text-nowrap">
                              {{ codecTypes[data.item.node.codec] }}
                            </div>
                          </template>

                          <template #cell(typesDRM)="data">
                            <div class="text-nowrap">
                              <span :id="data.item.node.id">
                                {{ drmTypes[data.item.node.drmType] }}
                              </span>
                            </div>
                          </template>

                          <template #cell(isActive)="data">
                            <div class="text-nowrap">
                              <b-badge :variant="data.item.node.isActive ? 'success' : 'danger'">
                                {{ data.item.node.isActive ? $t('dataGeneric.active') : $t('dataGeneric.inactive') }}
                              </b-badge>
                            </div>
                          </template>

                          <template #cell(action)="data">
                            <div class="text-nowrap">
                              <b-dropdown
                                variant="link"
                                toggle-class="text-decoration-none"
                                no-caret
                                dropleft
                                class="cursor-pointer"
                              >
                                <template #button-content>
                                  <feather-icon
                                    color="white"
                                    icon="MoreVerticalIcon"
                                    size="20"
                                  />
                                </template>
                                <b-dropdown-item @click.prevent="editMediaAsset(data)">
                                  <feather-icon
                                    icon="EditIcon"
                                    size="18"
                                  />
                                  {{ $t("editMediaAsset") }}
                                </b-dropdown-item>
                                <b-dropdown-item
                                  v-if="isSuperuser"
                                  @click.prevent="deleteMediaAsset(data.item.node.id)"
                                >
                                  <feather-icon
                                    icon="DeleteIcon"
                                    size="18"
                                  />
                                  {{ $t("deleteMediaAsset") }}
                                </b-dropdown-item>
                                <b-dropdown-item @click.prevent="subtitlesMediaAsset(data)">
                                  <feather-icon
                                    icon="FileTextIcon"
                                    size="18"
                                  />
                                  {{ $t("subtitlesMediaAsset") }}
                                </b-dropdown-item>
                                <b-dropdown-item
                                  @click="copyUrl(data)"
                                >
                                  <feather-icon
                                    icon="CopyIcon"
                                    size="18"
                                  />
                                  {{ $t("dataGeneric.copyUrl") }}
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </template>
                        </b-table>
                      <!-- <b-overlay
                        :show="busy"
                        no-wrap
                        variant="transparent"
                        :opacity="1.00"
                        :blur="'2px'"
                        rounded="lg"
                      >
                        <template #overlay>
                          <div
                            v-if="processing"
                            class="text-center p-4 bg-light "
                          >
                            <feather-icon
                              style="color:black"
                              icon="DownloadIcon"
                              size="15px"
                            />
                            <h1 style="color:black">
                              Download...
                            </h1>
                            <b-progress
                              min="0"
                              :max="totalContadorDescarga"
                              :value="recibiendoDescarga"
                              variant="dark"
                              height="11px"
                              class="mx-n4 rounded-0"
                            />
                          </div>
                          <div
                            v-else
                            ref="dialog"
                            tabindex="-1"
                            role="dialog"
                            aria-modal="false"
                            aria-labelledby="form-confirm-label"
                            class="text-center p-3"
                          >
                            <h1
                              style="color:white"
                              class="mb-5"
                            >
                              <strong id="form-confirm-label">{{ $t("code.title") }}</strong>
                            </h1>
                            <div class="d-flex">
                              <b-button
                                variant="success"
                                size="lg"
                                @click="onOK"
                              >
                                OK
                              </b-button>
                              <b-button
                                variant="danger"
                                class="ml-3"
                                size="lg"
                                @click="onCancel"
                              >
                                {{ $t("dataGeneric.cancel") }}
                              </b-button>
                            </div>
                          </div>
                        </template>
                      </b-overlay> -->
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="d-flex ">
                      <b-pagination
                        v-if="rows > perPage"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        size="lg"
                        @input="getMediaAsset()"
                      />
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
    <b-modal
      ref="MediaSelector"
      hide-footer
      size="xl"

      :title="$t('resources.selectFile')"
    >
      <div class="d-block text-center">
        <media-selector
          :msg="'VID'"
          @files="dataMedia"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <b-modal
      id="setting-modal"
      ref="setting-modal"
      title="Media Asset"
      hide-footer
      size="lg"
    >
      <div class="d-block">
        <media-asset
          :media-asset="mediaAsset"
          :update-action="updateAction"
          :resource="$route.params.id"
          class="m-2"
          :language="language"
          @refresh="searchMethod"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['setting-modal'].hide()"
      >
        {{ $t('dataGeneric.close') }}
      </b-button>
    </b-modal>
    <b-modal
      id="product-modal"
      ref="product-modal"
      hide-footer
      size="xl"
      :title="$t('code.sel')"
    >
      <div class="d-block text-center">
        <product-selector
          :columns="[
            { key: 'image', label: $t('purchases.category') },
            { key: 'name', label: $t('dataGeneric.name') },
            { key: 'vendor', label: $t('Vendedor') },
          ]"
          @confirm-selection="SelectProduct"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['product-platform'].hide()"
      >
        {{ $t("dataGeneric.close")
        }}
      </b-button>
    </b-modal>
    <b-modal
      id="subtitle-modal"
      ref="subtitleModal"
      hide-footer
      :size="modalSize"
      :title="$t('subtitles.subtitlesEditor')"
      @hide="resizeModal('lg')"
    >
      <subtitles
        :language="language"
        :media-asset="mediaAsset"
        :archivo-url-preview="archivoUrlPreview"
        @resizeModal="resizeModal($event)"
        @hideModal="hideModal"
      />
    </b-modal>
  </section>
</template>

<script>
import videojs from 'video.js'
import 'videojs-markers'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'
import {
  utf8ToB64, showToast, isEmpty, messageError, base64Encode, camelToSnake, checkFileType, getSubgroups, checkPermissions, isUserInGroup,
} from '@/store/functions'
import ProductSelector from '@/views/common/ProductSelector.vue'
import vSelect from 'vue-select'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BFormTextarea,
  BTab,
  BTabs,
  BButton,
  BRow,
  BDropdown,
  BBadge,
  BDropdownItem,
  BPagination,
  BTable,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BFormRow,
  BAlert,
  BOverlay,
  BModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import { getUserData } from '@/auth/utils'
import axios from '@axios'
import * as constants from '@core/utils/constants'
import ImageElementVue from '@/views/components/ImageElement.vue'
import MediaAsset from '../MediaAsset.vue'
import ViewFileModal from '../../../common/ViewFileModal.vue'
import TranscodingInfo from '../../../components/TranscodingInfo.vue'
import MediaSelector from '../../../common/MediaSelector.vue'
import Subtitles from '../../../common/Subtitles.vue'

export default {
  components: {
    BOverlay,
    ViewFileModal,
    Subtitles,
    BCardHeader,
    BCardTitle,
    BCardBody,
    ProductSelector,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BCard,
    BBadge,
    BTab,
    BTabs,
    ImageElementVue,
    BForm,
    MediaSelector,
    BFormRow,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BButton,
    MediaAsset,
    draggable,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    BTable,
    BFormTextarea,
    BAlert,
    TranscodingInfo,
    BModal,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    language: {
      type: String,
      default: null,
    },
    typeFile: {
      type: String,
      default: null,
    },
    base: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isEmpty,
      rows: 0,
      isSuperuser: false,
      perPage: 0,
      urlMediaAsset: null,
      idMediaAsset: null,
      updateAction: false,
      currentPage: 1,
      mediaAsset: {},
      typeMediaAsset: null,
      tableColumns: [
        { key: 'url', label: 'Url' },
        { key: 'typesMA', label: this.$t('typesMA') },
        { key: 'typesDRM', label: this.$t('typesDRM') },
        { key: 'codec', label: this.$t('tipoCodec') },
        { key: 'isActive', label: this.$t('code.status') },
        { key: 'action', label: this.$t('resources.tableHeader.actions') },
      ],
      video: null,
      required,
      viewName: this.$t('resources.resourceConfig'),
      viewDetail: this.$t('resources.resourceConfigSub'),
      resourceName: '',
      resourceDescription: null,
      resourceType: null,
      platformAllowTranscoding: false,
      couldTranscode: false,
      showMsgStateTranscode: false,
      transcodingState: 'NOT',
      android: null,
      androidTV: null,
      codecTypes: {
        H264: 'H264',
        H265: 'H265',
        MPEG: 'MPEG',
      },
      mediaAssetTypes: {
        MP3: 'MP3',
        MOV: 'MOV',
        MP4: 'MP4',
        HLS: 'HLS',
        DASH: 'DASH',
      },
      drmTypes: {
        null: this.$t('sinDRM'),
        FAI: 'FairPlay',
        WID: 'WideVine',
        PLA: 'PlayReady',
      },
      searchAsset: [],

      ios: null,
      iosTV: null,
      passStream: null,
      inputStream: null,
      thumbnailTrackUrl: '',
      userStream: null,
      typeStream: null,
      search: null,
      searching: null,
      fileName: '',
      webApp: null,
      videoResourceType: 'DIR',
      videoResourceTypeOption: [
        { value: 'BRU', text: this.$t('workId') },
        { value: 'BRD', text: this.$t('drmBru') },
        { value: 'URL', text: this.$t('URL fichero video') },
        { value: 'AUR', text: this.$t('fileAud') },
        { value: 'DIR', text: this.$t('Directo de video') },
        { value: 'AUD', text: this.$t('liveAud') },
        { value: 'PRE', text: this.$t('prevVid') },
      ],
      archivoUrl: null,
      archivoUrlPreview: null,
      idObra: null,
      textoMasInfo: null,
      escenarioTitulo: null,
      mostrarDatos: false,
      escenarioName: null,
      imageSrc: null,
      imageSelected: null,
      imageSelectedPlaceholder: null,
      question: null,
      fedError: null,
      fedOk: null,
      introTest: null,

      testId: null,
      answers: [
        { response: null, isTheCorrect: false },
        { response: null, isTheCorrect: false },
      ],
      showAlertNoCorrect: false,
      showInfoMaxAnswers: false,
      imageTestSrc: null,
      imageTestSelected: null,
      imageTestSelectedPlaceholder: null,
      imageCategory: null,
      backgroundTestSrc: null,
      backgroundTestSelected: null,
      backgroundTestSelectedPlaceholder: null,
      backgroundCategory: null,
      productId: null,
      productName: null,
      imageTestData: null,
      backgroundTestData: null,
      imagePlnData: null,
      correctAnswer: null,
      trailerCategory: null,
      answersToDelete: [],
      newAnswers: false,
      datosVideo: null,
      showResource: false,
      isSponsor: false,
      userData: getUserData(),
      modalSize: 'lg',
      hasSubgroups: false,
      subgroups: null,
      subgroup: null,
      hasActiveMediaAsset: false,
      checkPermissions,
    }
  },
  watch: {
    trailerCategory(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.trailerCategorySrc = value
            })
            .catch(() => {
              this.trailerCategorySrc = null
            })
        } else {
          this.trailerCategorySrc = null
        }
      }
    },
    imagePlnData(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageSelected = true
              this.imageSrc = value
            })
            .catch(() => {
              this.imageSrc = null
            })
        } else {
          this.imageSrc = null
        }
      }
    },
    imageTestData(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.imageTestSelected = true
              this.imageTestSrc = value
            })
            .catch(() => {
              this.imageTestSrc = null
            })
        } else {
          this.imageTestSrc = null
        }
      }
    },
    backgroundTestData(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then(value => {
              this.backgroundTestSelected = true
              this.backgroundTestSrc = value
            })
            .catch(() => {
              this.backgroundTestSrc = null
            })
        } else {
          this.backgroundTestSrc = null
        }
      }
    },
  },
  mounted() {
    if (this.userData.groups.edges.length !== 0) {
      this.isSponsor = isUserInGroup(this.userData, constants.sponsor)
    }
    this.isSuperuser = this.userData.isSuperuser

    const userData = getUserData()
    this.hasSubgroups = userData.profile.client.hasSubgroups
    if (this.hasSubgroups) {
      getSubgroups().then(result => {
        const { edges } = result.data.data.allSubgroups
        this.subgroups = edges.map(e => e.node)
      }).catch(err => {
        console.log(err)
      })
    }

    this.getResourceInfo()
    this.getMediaAsset()
    this.getPlatformAllowTranscoding()
  },
  beforeDestroy() {
    if (this.video) this.video.dispose()
  },
  methods: {
    searchMethod() {
      this.currentPage = 1
      this.getMediaAsset()
      this.$refs['setting-modal'].hide()
    },
    createMediaAsset() {
      this.mediaAsset = {}
      this.updateAction = false
      this.$refs['setting-modal'].show()
    },
    editMediaAsset(data) {
      this.mediaAsset = data.item.node
      this.updateAction = true
      this.$refs['setting-modal'].show()
    },
    subtitlesMediaAsset(data) {
      this.mediaAsset = data.item.node
      this.$refs.subtitleModal.show()
    },

    deleteMediaAsset(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('deleteContent'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
              mutation{
                deleteMediaAsset(id:"${id}"){
                  found
                }
              }
              `,
            }).then(r => {
              messageError(r, this)
              showToast(this.$t('success'), 1, this)

              this.currentPage = 1
              this.getMediaAsset()
            }).catch(() => {
              showToast(this.$t('error'), 2, this)
            })
        }
      }).catch(() => {

      })
    },
    disabledFieldsStream() {
      return this.typeStream === 'AWS' || this.typeStream === 'WOW'
    },

    getPlatformAllowTranscoding() {
      this.platformAllowTranscoding = this.userData.profile.client.isTranscoding
    },

    getMediaAsset() {
      const resourceId = utf8ToB64(`id:${this.$route.params.id}`)

      const query = `
      {
          allMediaasset(resource:"${resourceId}") {
            edges {
              node {
                id
                url(lang:"${this.language}")
                urlCloudfront(lang:"${this.language}")
                type
                drmType
                drmLicenseServUrl
                drmFairplayCertUrl
                codec
                maxQuality
                resource {
                  id
                }
                isActive
                createdAt
                client {
                  id
                  name
                }
              }
            }
          }  
        }`
      axios.post('', {
        query,
      })
        .then(result => {
          messageError(result, this)

          this.searchAsset = result.data.data.allMediaasset.edges
          this.hasActiveMediaAsset = this.searchAsset.some(e => e.node.isActive)
        })
        .catch(err => { console.log(err) })
    },
    SelectProduct(data) {
      if (!data.node.reference && (this.resourceType === 'A2B' || this.resourceType === 'A2C')) {
        this.selectedProductWithoutReference(data)
      } else {
        this.productId = data.node.id
        this.productName = data.node.name
        this.productReference = data.node?.reference
        document.getElementById('product-reference').value = data.node?.reference
      }
      this.$refs['product-modal'].hide()
    },
    validationMedia(data) {
      const keyContent = Object.keys(data.failedRules)
      if (keyContent.includes('required')) {
        return this.$t('required')
      }
      if (keyContent.includes('url')) {
        return this.$t('validUrl')
      }
      return ''
    },
    async copyUrl(data) {
      const url = data.item.node.urlCloudfront
      try {
        await navigator.clipboard.writeText(url)
        showToast(this.$t('code.tCopy'), 1, this)
      } catch (err) {
        showToast(this.$t('code.errorCopy'), 2, this)
        console.error('Error al copiar:', err)
      }
    },
    validationFormInfo() {
      return new Promise(resolve => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.updateResource()
          } else {
            // reject();
          }
        })
      })
    },
    back() {
      window.history.back()
    },
    chooseImage(imageClicked) {
      this.imageClicked = imageClicked
      document.getElementById(imageClicked).click()
    },
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case `imagePlnData${this.language}`:
          this.imagePlnData = data
          break
        case `imageTestData${this.language}`:
          this.imageTestData = data
          break
        case `backgroundTestData${this.language}`:
          this.backgroundTestData = data
          break

        default:
          break
      }
    },
    getResourceInfo() {
      const query = `
        query($id: String){
            allResources(id:$id){
              edges {
                  node{
                    id
                    creator {
                      id
                    }
                    thumbnailTrackUrl
                    name(lang:"${this.language}")
                    description(lang:"${this.language}")
                    type
                    videotype
                    directUrl(lang:"${this.language}")
                    directUrlOriginal(lang:"${this.language}")
                    idObra
                    inputStream(lang:"${this.language}")
                    userStream
                    passwordStream
                    typeStream
                    transcodingState
                    idTranscoding
                    file(lang:"${this.language}") {
                      id
                      staticUrl
                      media
                      fileUrl
                      name
                    }
                    test{
                      id
                      name(lang:"${this.language}")
                      correct
                      intro(lang:"${this.language}")
                      failFeedback(lang:"${this.language}")
                      successFeedback(lang:"${this.language}")
                      image
                      background
                      imageUrl(lang:"${this.language}")
                      backgroundUrl(lang:"${this.language}")
                      testAnswer {
                        id
                        response(lang:"${this.language}")
                        order
                      }
                    }
                    plain{
                      id
                      name
                      title(lang:"${this.language}")
                      text(lang:"${this.language}")
                      image
                      imageUrl(lang:"${this.language}")
                    }
                    product{
                      id
                      name
                    }
                    remoteProductId
                    urlApp
                    urlAppAndroidTv
                    urlAppIos
                    urlAppIosTv
                    urlAppWeb
                    subgroup {
                      id
                      name
                    }
                  }
              }
            }
        }
      `
      const { id } = this.$route.params
      const variables = { id: `${id}` }
      axios
        .post('', {
          query,
          variables,
        })
        .then(res => {
          messageError(res, this)
          let type = this.typeFile

          if (res.data.data.allResources.edges.length) {
            const data = res.data.data.allResources.edges[0].node
            let authorized = false
            if (this.isSponsor) {
              if (data.creator !== null) {
                if (data.creator.id === this.userData.id) authorized = true
              }
              if (!authorized) {
                this.$router.push({ name: 'misc-not-authorized' })
              }
            }
            this.resourceName = data.name
            this.thumbnailTrackUrl = data.thumbnailTrackUrl
            this.resourceDescription = data.description
            this.resourceType = data.type
            this.inputStream = data.inputStream
            this.userStream = data.userStream
            this.passStream = data.passwordStream
            this.typeStream = data.typeStream
            this.transcodingState = data.transcodingState
            this.idTranscoding = data.idTranscoding
            this.subgroup = data.subgroup

            if (this.resourceType === 'DEE') {
              this.android = data.urlApp
              this.androidTV = data.urlAppAndroidTv
              this.ios = data.urlAppIos
              this.iosTV = data.urlAppIosTv
              this.webApp = data.urlAppWeb
            } else if (
              this.resourceType === 'BUY'
              || this.resourceType === 'CAR'
              || this.resourceType === 'A2B'
              || this.resourceType === 'A2C'
            ) {
              this.productId = data?.product?.id
              this.productName = data?.product?.name
              if (
                this.resourceType === 'A2B'
                || this.resourceType === 'A2C'
              ) {
                this.productReference = data.remoteProductId
              }
            } else if (this.resourceType === 'TST') {
              this.imageTestData = data.test.imageUrl
              this.backgroundTestData = data.test.backgroundUrl
              this.correctAnswer = data.test.correct
              this.testId = data.test.id
              this.answers = data.test.testAnswer
                .sort((a, b) => a.order - b.order)
                .map(item => ({
                  response: item.response,
                  isTheCorrect:
                    item.order === this.correctAnswer,
                  answerId: item.id,
                  order: item.order,
                }))
              this.introTest = data.test.intro
              this.question = data.test.name
              this.fedOk = data.test.successFeedback
              this.fedError = data.test.failFeedback
            } else if (this.resourceType === 'VID') {
              this.videoResourceType = data.videotype
              this.archivoUrl = isEmpty(data.directUrlOriginal) ? '' : data.directUrlOriginal
              this.archivoUrlPreview = isEmpty(data.directUrl) ? '' : data.directUrl
              this.idObra = data.idObra
              let urlFile = ''
              if (!isEmpty(data.directUrl)) {
                urlFile = data.directUrl
              } else if (!isEmpty(data.file)) {
                urlFile = data.file.fileUrl
              }
              this.datosVideo = {
                type: data.videotype,
                fileUrl: urlFile,
                name: data.file != null ? data.file.name : data.name,
              }
              if (this.datosVideo.fileUrl) {
                if (this.datosVideo.fileUrl.includes('jpg') || this.datosVideo.fileUrl.includes('png')) {
                  type = 'IMG'
                } else if (this.datosVideo.fileUrl.includes('mp3')) {
                  type = 'AUD'
                } else if (this.datosVideo.fileUrl.includes('vtt') || this.datosVideo.fileUrl.includes('srt') || this.datosVideo.fileUrl.includes('x-subrip')) {
                  type = 'SUB'
                } else {
                  type = 'VID'
                }
              }
              checkFileType(this.datosVideo.fileUrl)
                .then(mimeType => {
                  if (this.platformAllowTranscoding && (data.videotype === 'BUK' || data.videotype === 'URL') && ['MP3', 'MP4', 'MOV'].includes(mimeType)) {
                    this.showMsgStateTranscode = true
                    // Se ha tenido en cuenta de que si hay algun mediasset transcodificado, no se pueda transcodificar
                    const searchAssetTranscoding = this.searchAsset.filter(edge => ['HLS', 'DASH'].includes(edge.node.type))
                    if (searchAssetTranscoding.length === 0 && ['NOT', 'ERT'].includes(data.transcodingState)) {
                      this.couldTranscode = true
                    }
                  }
                }).catch(error => {
                  messageError(error, this)
                })
            } else if (this.resourceType === 'PLN') {
              this.plainId = data.plain.id
              this.escenarioTitulo = data.plain.title
              this.escenarioName = data.plain.name
              this.textoMasInfo = data.plain.text
              this.imagePlnData = data.plain.imageUrl
            }
            if (!authorized && this.isSponsor) this.mostrarDatos = false
            else this.mostrarDatos = true

            this.$emit('return', {
              name: this.resourceName, viewName: this.viewName, viewDetail: this.viewDetail, typeFile: type,
            })
          }
        })
    },
    async updateResource() {
      this.showResource = true

      let objectVariables = []
      let valueResource = ''
      if (this.videoResourceType !== 'BUK') {
        if (this.videoResourceType === 'BRU') {
          valueResource = this.idObra?.trim()
        } else {
          valueResource = this.archivoUrl?.trim()
        }
      }

      if (this.resourceType === 'VID') {
        objectVariables = [
          { title: `name${this.language}`, value: this.resourceName, type: 'String' },
          { title: `description${this.language}`, value: this.resourceDescription, type: 'String' },
          { title: 'subgroup', value: this.subgroup?.id, type: 'ID' },
          {
            title: `directUrl${this.language}`,
            value: valueResource,
            type: 'String',
          },
          { title: `inputStream${this.language}`, value: this.inputStream, type: 'String' },
        ]

        if (this.datosVideo.id) {
          objectVariables.push(
            { title: `file${this.language}`, value: this.datosVideo.id, type: 'ID' },
          )
        }
        if (this.language === this.base) {
          objectVariables.push(
            { title: 'videotype', value: this.videoResourceType, type: 'ContentsResourceVideotypeChoices' },
            { title: 'userStream', value: this.userStream, type: 'String' },
            { title: 'passwordStream', value: this.passStream, type: 'String' },
            { title: 'thumbnailTrackUrl', value: this.thumbnailTrackUrl, type: 'String' },
          )
        }
        const variablesVID = {}
        let mutation = 'mutation('
        let queryVID = `{updateResource(id:"${this.$route.params.id}",input:{\n
        `
        objectVariables.forEach(element => {
          variablesVID[element.title] = element.value
          mutation += `$${element.title}:${element.type},\n`
          queryVID += `${element.title}:$${element.title},\n`
        })
        mutation += ')'
        queryVID += `}){
        resource {
              id
              
            }
        }
      }`
        queryVID = mutation + queryVID
        const dataVID = new FormData()
        dataVID.append('query', queryVID)
        dataVID.append('variables', JSON.stringify(variablesVID))
        axios.post('', dataVID).then(res => {
          messageError(res, this)
          this.showResource = false
          showToast(this.$t('success'), 1, this)
          this.getResourceInfo()
        }).catch(() => {
          this.showResource = false
          showToast(this.$t('error'), 2, this)
        })
      } else if (this.resourceType === 'PLN') {
        objectVariables = [
          { title: `title${this.language}`, value: this.escenarioTitulo, type: 'String' },
          { title: `text${this.language}`, value: this.textoMasInfo, type: 'String' },
          { title: 'subgroup', value: this.subgroup?.id, type: 'ID' },
        ]
        if (this.language === this.base) {
          objectVariables.push(
            { title: 'name', value: this.escenarioName, type: 'String' },
          )
        }
        const variablesPLN = {}
        let mutation = 'mutation('
        let queryPLN = `{updatePlain(id:"${this.plainId}",input:{\n
        `
        objectVariables.forEach(element => {
          variablesPLN[element.title] = element.value
          mutation += `$${element.title}:${element.type},\n`
          queryPLN += `${element.title}:$${element.title},\n`
        })
        mutation += ')'
        queryPLN += `}){
          plain {
                  id
                  name
                  title
                  text
              }
        }
      }`
        queryPLN = mutation + queryPLN

        const dataPLN = new FormData()
        dataPLN.append('query', queryPLN)
        dataPLN.append('variables', JSON.stringify(variablesPLN))
        dataPLN.append(`image_${camelToSnake(this.language)}`, this.imagePlnData)

        axios.post('', dataPLN).then(response => {
          messageError(response, this)

          const secondObjectVariables = [
            { title: `name${this.language}`, value: this.resourceName, type: 'String' },
            { title: `description${this.language}`, value: this.resourceDescription, type: 'String' },
            { title: 'subgroup', value: this.subgroup?.id, type: 'ID' },
          ]

          const variablesResVID = {}
          let updateResource = 'mutation('
          let queryResPLN = `{updateResource(id:"${this.$route.params.id}",input:{\n
        `
          secondObjectVariables.forEach(element => {
            variablesResVID[element.title] = element.value
            updateResource += `$${element.title}:${element.type},\n`
            queryResPLN += `${element.title}:$${element.title},\n`
          })
          updateResource += ')'
          queryResPLN += `}){
          resource {
                    id
                    name
                    description
                  }
        }
      }`
          queryResPLN = updateResource + queryResPLN

          const dataResVID = new FormData()
          dataResVID.append('query', queryResPLN)
          dataResVID.append('variables', JSON.stringify(variablesResVID))
          axios.post('', dataResVID).then(res => {
            messageError(res, this)
            this.getResourceInfo()

            this.showResource = false
            showToast(this.$t('success'), 1, this)
          }).catch(() => {
            this.showResource = false
            showToast(this.$t('error'), 2, this)
          })
        })
      } else if (this.resourceType === 'DEE') {
        objectVariables = [
          { title: `name${this.language}`, value: this.resourceName, type: 'String' },
          { title: `description${this.language}`, value: this.resourceDescription, type: 'String' },
          { title: 'subgroup', value: this.subgroup?.id, type: 'ID' },
        ]
        if (this.language === this.base) {
          objectVariables.push(
            { title: 'urlApp', value: this.android, type: 'String' },
            { title: 'urlAppAndroidTv', value: this.androidTV, type: 'String' },
            { title: 'urlAppIos', value: this.ios, type: 'String' },
            { title: 'urlAppIosTv', value: this.iosTV, type: 'String' },
            { title: 'urlAppWeb', value: this.webApp, type: 'String' },
          )
        }
        const variablesDEE = {}
        let mutation = 'mutation('
        let queryDEE = `{updateResource(id:"${this.$route.params.id}",input:{\n
        `
        objectVariables.forEach(element => {
          variablesDEE[element.title] = element.value
          mutation += `$${element.title}:${element.type},\n`
          queryDEE += `${element.title}:$${element.title},\n`
        })
        mutation += ')'
        queryDEE += `}){
          resource {
                id
                name
                description
                urlApp
                urlAppAndroidTv
                urlAppIos
                urlAppIosTv
                urlAppWeb
              }
            }
          }`
        queryDEE = mutation + queryDEE
        const dataDEE = new FormData()
        dataDEE.append('query', queryDEE)
        dataDEE.append('variables', JSON.stringify(variablesDEE))
        axios.post('', dataDEE).then(res => {
          messageError(res, this)
          this.getResourceInfo()

          this.showResource = false
          showToast(this.$t('success'), 1, this)
        }).catch(() => {
          this.showResource = false
          showToast(this.$t('error'), 2, this)
        })
      } else if (this.resourceType === 'A2B' || this.resourceType === 'A2C') {
        objectVariables = [
          { title: `name${this.language}`, value: this.resourceName, type: 'String' },
          { title: `description${this.language}`, value: this.resourceDescription, type: 'String' },
          { title: 'subgroup', value: this.subgroup?.id, type: 'ID' },
        ]
        if (this.language === this.base) {
          objectVariables.push(
            { title: 'remoteProductId', value: this.productReference, type: 'String' },
            { title: 'product', value: this.productId, type: 'ID' },
          )
        }
        const variablesA2 = {}
        let mutation = 'mutation('
        let queryA2 = `{updateResource(id:"${this.$route.params.id}",input:{\n
        `
        objectVariables.forEach(element => {
          variablesA2[element.title] = element.value
          mutation += `$${element.title}:${element.type},\n`
          queryA2 += `${element.title}:$${element.title},\n`
        })
        mutation += ')'
        queryA2 += `}){
          resource {
                id
                name
                type
                description
                remoteProductId
              }
            }
          }`
        queryA2 = mutation + queryA2
        const dataA2 = new FormData()
        dataA2.append('query', queryA2)
        dataA2.append('variables', JSON.stringify(variablesA2))
        axios.post('', dataA2).then(res => {
          messageError(res, this)
          this.getResourceInfo()

          this.showResource = false
          showToast(this.$t('success'), 1, this)
        }).catch(() => {
          this.showResource = false
          showToast(this.$t('error'), 2, this)
        })
      } else if (this.resourceType === 'BUY' || this.resourceType === 'CAR') {
        objectVariables = [
          { title: `name${this.language}`, value: this.resourceName, type: 'String' },
          { title: `description${this.language}`, value: this.resourceDescription, type: 'String' },
          { title: 'subgroup', value: this.subgroup?.id, type: 'ID' },
        ]
        if (this.language === this.base) {
          objectVariables.push(
            { title: 'product', value: this.productId, type: 'ID' },
          )
        }
        const variablesA2 = {}
        let mutation = 'mutation('
        let queryA2 = `{updateResource(id:"${this.$route.params.id}",input:{\n
        `
        objectVariables.forEach(element => {
          variablesA2[element.title] = element.value
          mutation += `$${element.title}:${element.type},\n`
          queryA2 += `${element.title}:$${element.title},\n`
        })
        mutation += ')'
        queryA2 += `}){
          resource {
                id
                name
                type
                description
                
              }
            }
          }`
        queryA2 = mutation + queryA2
        const dataA2 = new FormData()
        dataA2.append('query', queryA2)
        dataA2.append('variables', JSON.stringify(variablesA2))
        axios.post('', dataA2).then(res => {
          messageError(res, this)
          this.getResourceInfo()

          this.showResource = false
          showToast(this.$t('success'), 1, this)
        }).catch(() => {
          this.showResource = false
          showToast(this.$t('error'), 2, this)
        })
      } else if (this.resourceType === 'TST') {
        await this.editAnswer()
        const answerCorrect = this.answers.filter(
          item => item.isTheCorrect === true,
        )

        objectVariables = [
          { title: `name${this.language}`, value: this.question, type: 'String' },
          { title: `intro${this.language}`, value: this.introTest, type: 'String' },
          { title: `failFeedback${this.language}`, value: this.fedError, type: 'String' },
          { title: `successFeedback${this.language}`, value: this.fedOk, type: 'String' },
        ]
        if (this.language === this.base) {
          objectVariables.push(
            { title: 'correct', value: answerCorrect[0].order, type: 'Int' },
          )
        }
        const variablesUpdateTest = {}
        let mutation = 'mutation('
        let queryUpdateTest = `{updateTest(id:"${this.testId}",input:{\n
        `
        objectVariables.forEach(element => {
          variablesUpdateTest[element.title] = element.value
          mutation += `$${element.title}:${element.type},\n`
          queryUpdateTest += `${element.title}:$${element.title},\n`
        })
        mutation += ')'
        queryUpdateTest += `}){
            test {
                  id
                  correct
                }
            }
          }`
        queryUpdateTest = mutation + queryUpdateTest
        const dataUpdateTest = new FormData()
        dataUpdateTest.append('variables', JSON.stringify(variablesUpdateTest))
        dataUpdateTest.append('query', queryUpdateTest)
        dataUpdateTest.append(`image_${camelToSnake(this.language)}`, this.imageTestData)
        dataUpdateTest.append(`background_${camelToSnake(this.language)}`, this.backgroundTestData)

        axios.post('', dataUpdateTest).then(res => {
          messageError(res, this)

          objectVariables = [
            { title: `name${this.language}`, value: this.resourceName, type: 'String' },
            { title: `description${this.language}`, value: this.resourceDescription, type: 'String' },
            { title: 'subgroup', value: this.subgroup?.id, type: 'ID' },
          ]

          const variablesEditRes = {}
          let updateResource = 'mutation('
          let queryEditRes = `{updateResource(id:"${this.$route.params.id}",input:{\n
        `
          objectVariables.forEach(element => {
            variablesEditRes[element.title] = element.value
            updateResource += `$${element.title}:${element.type},\n`
            queryEditRes += `${element.title}:$${element.title},\n`
          })
          updateResource += ')'
          queryEditRes += `}){
          resource {
                  id
                  name
                  description
                }
            }
          }`
          queryEditRes = updateResource + queryEditRes
          const lastData = new FormData()
          lastData.append('variables', JSON.stringify(variablesEditRes))
          lastData.append('query', queryEditRes)
          axios
            .post('', lastData)
            .then(response => {
              messageError(response, this)
              this.getResourceInfo()

              showToast(this.$t('success'), 1, this)

              this.showResource = false
            })
            .catch(() => {
              showToast(this.$t('error'), 2, this)
              this.showResource = false
            })
        })
      } else if (this.resourceType === 'BUY' || this.resourceType === 'CAR') {
        objectVariables = [
          { title: `name${this.language}`, value: this.resourceName, type: 'String' },
          { title: `description${this.language}`, value: this.resourceDescription, type: 'String' },
          { title: 'subgroup', value: this.subgroup?.id, type: 'ID' },
        ]

        const variablesEditRes = {}
        let mutation = 'mutation('
        let queryEditRes = `{updateResource(id:"${this.$route.params.id}",input:{\n
        `
        objectVariables.forEach(element => {
          variablesEditRes[element.title] = element.value
          mutation += `$${element.title}:${element.type},\n`
          queryEditRes += `${element.title}:$${element.title},\n`
        })
        mutation += ')'
        queryEditRes += `}){
          resource {
                  id
                  name
                  description
                }
            }
          }`
        queryEditRes = mutation + queryEditRes
        const lastData = new FormData()
        lastData.append('variables', JSON.stringify(variablesEditRes))
        lastData.append('query', queryEditRes)
        axios
          .post('', lastData)
          .then(res => {
            messageError(res, this)
            showToast(this.$t('success'), 1, this)
            this.getResourceInfo()

            this.showResource = false
          })
          .catch(() => {
            showToast(this.$t('error'), 2, this)
            this.showResource = false
          })
      }
    },
    generateRandomOrder(numeroEvitar) {
      let numeroInicial = 0
      do {
        numeroInicial = Math.floor(Math.random() * 97) + 1
      } while (
        // eslint-disable-next-line no-loop-func
        [0, 1, -1].some(offset => [numeroEvitar, numeroEvitar + offset].includes(numeroInicial))
      )

      // return [numeroInicial, numeroInicial + 1, numeroInicial + 2, numeroInicial + 3];
      return numeroInicial
    },
    dataMedia(data) {
      this.datosVideo = {
        type: data.type,
        fileUrl: data.fileUrl,
        name: data.name,
        id: data.id,
      }
      this.fileName = data.name
      this.$refs.MediaSelector.hide()
    },
    async editAnswer() {
      const nOrder = this.generateRandomOrder(this.answers[0].order)
      let query = 'mutation{'

      // eslint-disable-next-line array-callback-return
      this.answers.map((answer, index) => {
        if (this.language === this.base) this.answers[index].order = nOrder + index
        if (answer.answerId) {
          query += `up_${index + 1}: updateAnswer(id: ${answer.answerId}, 
        input: {
              response${this.language}: "${isEmpty(answer.response) ? '' : answer.response}",
              ${this.language === this.base ? `order: ${nOrder + index}` : ''}
            }){
              answer {
                id
                response
                order
              }
            }`
        } else {
          query += `cre_${index + 1}: createAnswer(
        input: {
              response: "${isEmpty(answer.response) ? '' : answer.response}",
              response${this.language}: "${isEmpty(answer.response) ? '' : answer.response}",
              test: "${this.testId}",
              order: ${nOrder + index},
              client: "${this.userData.profile.client.id}"

            }){
              answer {
                id
                response
                order
              }
            }`
        }
      })
      query += '}'

      await axios.post('', { query }).then(result => {
        messageError(result, this)
        showToast(this.$t('success'), 1, this)
      }).catch(() => {
        showToast(this.$t('error'), 2, this)
      })
    },
    deleteAnswers(id) {
      const queryDelete = `
        mutation($deleteIds: [ID]!) {
            batchDeleteAnswers(ids: $deleteIds){
              deletedIds
              deletionCount
            }
          }       
      `

      const variablesDelete = {
        deleteIds: [id],
      }

      const deleteFormData = new FormData()
      deleteFormData.append('variables', JSON.stringify(variablesDelete))
      deleteFormData.append('query', queryDelete)
      axios.post('', deleteFormData).then(result => {
        messageError(result, this)
        showToast(this.$t('success'), 1, this)
      }).catch(() => {
        showToast(this.$t('error'), 2, this)
      })
    },
    checkOtherAnswers(indexTrue) {
      let contInt = 0
      this.answers.forEach((element, index) => {
        if (index !== indexTrue) {
          // eslint-disable-next-line no-param-reassign
          element.isTheCorrect = false
        }
        if (element.isTheCorrect) {
          contInt += 1
        }
      })
      if (contInt === 0) {
        this.answers[indexTrue].isTheCorrect = true
      }
    },
    addNewAnswer() {
      this.answers = [
        ...this.answers,
        { response: null, isTheCorrect: false, answerId: null },
      ]
      this.newAnswers = true
      if (this.answers.length === 4) this.showInfoMaxAnswers = true
    },
    dropLastAnswer() {
      if (this.answers[this.answers.length - 1].answerId) {
        const id = this.answers[this.answers.length - 1].answerId
        this.answers.pop()
        this.deleteAnswers(id)
      }
    },
    dropSpecificQuestion(index) {
      if (this.answers[index].answerId) {
        const id = this.answers[index].answerId
        this.answers.splice(index, 1)
        this.deleteAnswers(id)
      }
    },
    videoMaker() {
      this.video = videojs('demo')

      this.video.on('ready', function () {
        try {
          this.video.vhs = null
          // eslint-disable-next-line no-empty
        } catch (error) { }
      })
    },
    hideModal() {
      this.$refs.subtitleModal.hide()
      this.$refs.MediaSelector.hide()
    },
    resizeModal(size) {
      this.modalSize = size
    },
    selectedProductWithoutReference(data) {
      this.$swal({
        title: this.$t('warning'),
        text: this.$t('noExternalReferenceFound'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yNext'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.productId = data.node.id
            this.productName = data.node.name
            this.productReference = null
            document.getElementById('product-reference').value = ''
            document.getElementById('product-reference').focus()
          }
        })

        .catch(() => { })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

#editarResources .base-image-video {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#editarResources .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#editarResources .placeholder:hover {
  background: #e0e0e0;
}

#editarResources .file-input {
  display: none;
}

#editarResources .answer-box {
  height: 70px;
  padding: 15px;
  opacity: 0.6;
}

#editarResources .answer-num {
  font-weight: 800;
  color: white;
  font-size: large;
}

#editarResources #answers-card {
  background-color: #374565;
}

#editarResources .tamanio {
  width: 25vw;
  height: 15vw;
}

#acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}
#acortarPadre {
  width: 500px;
  overflow: hidden;
  white-space: nowrap;
}

.content-table {
  overflow-x: scroll; /* Forzar la barra de desplazamiento */
  // border: 1px solid red;
  white-space: nowrap; /* Evita que las celdas se envuelvan */
  min-height: 200px;
}

</style>
